/* fonts */

@font-face {
	font-family: 'DMSerifDisplay';
	src: url('../font/dmserifdisplay-regular-webfont.woff');
	src: local('☺'),
		url('../font/dmserifdisplay-regular-webfont.woff') format('woff'),
		url('../font/dmserifdisplay-regular-webfont.woff2') format('woff2');
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;1,300&display=swap');
