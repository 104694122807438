@import 'scss/reset';
@import 'scss/fonts';
@import 'scss/responsive';

$base-color-black: #0d0d0d;
$base-color-green: #05f240;

::selection {
	background: $base-color-green; /* WebKit/Blink Browsers */
}
::-moz-selection {
	background: #ffb7b7; /* Gecko Browsers */
}

// custom
body {
	color: $base-color-black;
	font-family: 'Roboto', sans-serif;
}

main {
	margin: 4rem 3rem 0;
	padding-bottom: 4rem;
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 75vw;
	max-width: 1200px;
	margin-left: 370px;
	gap: 3rem;
	@include for-size(desktop) {
		width: 100%;
		max-width: 100%;
		margin: 0 auto;
		gap: 3rem;
		margin-top: 6rem;
		padding: 3rem;
		margin-left: 0;
	}
	@include for-size(phone-only) {
		grid-template-columns: 1fr;
		max-width: 100vw;
		margin: 6rem auto 4rem;
		padding: 2rem;
	}
}

h1 {
	font-family: 'DMSerifDisplay';
	position: fixed;
	top: 3rem;
	left: 3rem;
	margin: 1rem 0;
	font-size: 2.25rem;
	@include for-size(desktop) {
		font-size: 1.7rem;
	}
	@include for-size(phone-only) {
		left: 2rem;
	}
}

h2 {
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 0.075em;
	font-size: 1.25rem;
	line-height: 45px;
	@include for-size(desktop) {
		font-size: 1.1rem;
	}
}

h3 {
	@extend h2;
	font-weight: normal;
	text-transform: capitalize;
}

.about-me {
	p {
		letter-spacing: 0.8px;
		font-size: 1.3rem;
		line-height: 2em;
		margin: 0 auto 2rem;
		text-align: left;
		letter-spacing: 0;
		font-weight: 300;
		@include for-size(desktop) {
			font-size: 1.1rem;
		}
	}

	.email-link {
		text-decoration: none;
		color: $base-color-black;
		font-style: italic;
		&:hover {
			font-weight: bold;
		}
	}
}

.item {
	margin-bottom: 1.5rem;
	display: inline-block;
	width: 100%;
	transition: 0.3s;
	a {
		display: grid;
		grid-template-columns: 3fr 2fr;
		text-decoration: none;
		color: $base-color-black;
		font-weight: 300;
		align-items: center;
		margin-bottom: 2px;
		line-height: 2rem;
		&:hover {
			margin-bottom: 0;
			border-bottom: 2px solid $base-color-black;
		}
		.client {
			text-align: right;
			@include for-size(phone-only) {
				font-size: 1rem;
			}
		}
		.tech {
			@include for-size(phone-only) {
				font-size: 1rem;
			}
		}
	}
}

.webgl {
	position: absolute !important;
}
